import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ContactInfo } from '../models/contact.info';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private http: HttpClient) { }

  sendContactInfo(contactInfo: ContactInfo) {
    return this.http
      .post<any>(
        `${environment.api.baseUrl}/api/sendContactInfo`,
        {
          name: contactInfo.name,
          email: contactInfo.email,
          subject: contactInfo.subject,
          message: contactInfo.message
        },
      )
      .pipe(
        retry(1),
        catchError((e) => this.handleEmailError(e))
      );
  }

  handleEmailError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Envio de información no disponible, intente más tarde: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
