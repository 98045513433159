<section id="home" class="section welcome-area bg-inherit d-flex align-items-center h-100vh">
    <div class="container">
        <div class="row align-items-center">
            <!-- Welcome Intro Start -->
            <div class="col-12 col-md-7 col-lg-6">
                <div class="welcome-intro">
                    <h1 class="fw-5">A best way to <span>start</span> your business</h1>
                    <p class="my-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Temporibus commodi, voluptate quaerat iure quidem expedita eos a blanditiis sint modi est error veniam facere eum at doloribus amet, nobis ut.</p>
                    <div class="button-group">
                        <a href="#" class="btn btn-bordered">Get Started</a>
                        <a href="#" class="btn btn-bordered d-none d-sm-inline-block">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5 col-lg-6 pt-4 pt-md-0">
                <!-- Welcome Thumb -->
                <div class="welcome-thumb text-center">
                    <img src="assets/img/login_3.svg" alt="">
                </div>
            </div>
        </div>
    </div>
</section>