<div class="reviews-page">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-one></app-header-one>
        <app-breadcrumb-reviews></app-breadcrumb-reviews>
        <div class="bg-gray">
            <app-reviews></app-reviews>
        </div>
        <app-newsletter></app-newsletter>
        <app-footer></app-footer>
    </div>
</div>