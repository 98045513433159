<div class="inner-wrapper d-flex flex-column align-items-center justify-content-between p-4">
    <a href="index.html">
        <img src="assets/img/inner_logo.png" alt="">
    </a>
    <!-- ***** Download Page Area Start ***** -->
    <div class="download-page-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-md-6 order-2 order-md-1">
                    <h2 class="mb-3">Your download should begin automaticly.</h2>
                    <h6>If it doesn’t start automatically,<br>please <a href="#">click here</a> to downlaod manually.</h6>
                </div>
                <div class="col-12 col-sm-8 col-md-6 col-lg-5 mx-auto order-1 order-md-2 mb-5 mb-md-0 pt-4 pt-md-0">
                    <img src="assets/img/download_thumb.png" alt="">
                </div>
            </div>
        </div>
    </div>
    <!-- ***** Download Page Area End ***** -->
    <div class="footer-bottom">
        <!-- Copyright Area -->
        <div class="copyright-area border-0 pt-4 pt-md-0">
            <p>Made with <i class="icofont-heart-alt"></i> By <a href="#">Theme Land</a></p>
        </div>
    </div>
</div>