<section class="section faq-area bg-gray ptb_100">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Faq -->
                <div class="single-faq bg-white px-4 py-3">
                    <div class="media">
                        <img class="rounded-circle avatar-sm" src="assets/img/idea.jpg" alt="">
                        <div class="media-body align-self-center ml-3">
                            <h5>Como puedo instalar esta App?</h5>
                        </div>
                    </div>
                    <p class="mt-3">Pronto saldrá la app, por ahora solo es a través de nuestra página web: https://app.mimercapp.com</p>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Faq -->
                <div class="single-faq bg-white px-4 py-3">
                    <div class="media">
                        <img class="rounded-circle avatar-sm" src="assets/img/idea.jpg" alt="">
                        <div class="media-body align-self-center ml-3">
                            <h5>Cuál es el precio de la App o Servicios?</h5>
                        </div>
                    </div>
                    <p class="mt-3">Esta app es grátuita para los usuarios, puedes comunicarte con tus proveedores y hacer pedidos. En caso de que quieras publicar tus productos, entonces el servicio tiene un costo mensual.</p>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Faq -->
                <div class="single-faq bg-white px-4 py-3">
                    <div class="media">
                        <img class="rounded-circle avatar-sm" src="assets/img/idea.jpg" alt="">
                        <div class="media-body align-self-center ml-3">
                            <h5>Tienen una forma de contacto?</h5>
                        </div>
                    </div>
                    <p class="mt-3">Por favor escribenos cualquier duda a nuestro correo electrónico: mercapp.solution@gmail.com</p>
                </div>
            </div>

            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Faq -->
                <div class="single-faq bg-white px-4 py-3">
                    <div class="media">
                        <img class="rounded-circle avatar-sm" src="assets/img/idea.jpg" alt="">
                        <div class="media-body align-self-center ml-3">
                            <h5>Como elimino mi acceso con Facebook?</h5>
                        </div>
                    </div>
                    <p class="mt-3">Ve a esta liga para instrucciones más detalladas</p>
                </div>
            </div>
        
        </div>
    </div>
</section>