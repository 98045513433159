import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-reviews',
  templateUrl: './breadcrumb-reviews.component.html',
  styleUrls: ['./breadcrumb-reviews.component.css']
})
export class BreadcrumbReviewsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
