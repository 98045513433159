<div class="landing">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-landing></app-header-landing>
        <app-hero-landing></app-hero-landing>
        <app-branding></app-branding>
        <app-work></app-work>
        <app-features></app-features>
        <app-screenshot></app-screenshot>
        <app-newsletter></app-newsletter>
        <app-download></app-download>
        <app-contact></app-contact>
        <app-footer></app-footer>
    </div>
</div>