<section class="section coming-soon">
    <div class="row h-100vh w-100 d-flex p-0 m-0">
        <div class="col-lg p-0 d-none d-lg-block">
            <div class="bg-wrapper d-flex flex-column justify-content-between p-4 bg-overlay">
                <!-- Logo -->
                <a class="navbar-brand d-table" href="index.html">
                    <img class="logo" src="assets/img/logo_white.png" alt="">
                </a>
                <div class="footer-bottom">
                    <!-- Copyright Area -->
                    <div class="copyright-area border-0">
                        <p class="text-white">Made with <i class="icofont-heart-alt text-white"></i> By <a class="text-white" href="#">Theme Land</a></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-8 align-self-center p-0">
            <div class="countdown-content">
                <h1 class="text-center text-uppercase mb-5">Coming Soon</h1>
                <div class="countdown-times">
                    <div class='countdown d-flex justify-content-center' data-date="2021-01-01"></div>
                </div>
            </div>
        </div>
    </div>
</section>