<div class="inner-wrapper d-flex flex-column align-items-center justify-content-between p-4">
    <a href="index.html">
        <img src="assets/img/inner_logo.png" alt="">
    </a>
    <!-- ***** Error Two Area Start ***** -->
    <div class="error-area">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-8 col-md-6 mx-auto mb-5 pt-4">
                    <img src="assets/img/error_thumb.png" alt="">
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-10 col-lg-8 mx-auto text-center mb-5">
                    <h2>We think you get lost!</h2>
                    <p class="my-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto beatae, asperiores tempore repudiandae saepe aspernatur unde voluptate sapiente quia ex.</p>
                    <a href="index.html" class="btn btn-primary"><span>Go Home</span></a>
                </div>
            </div>
        </div>
    </div>
    <!-- ***** Error Two Area End ***** -->
    <div class="footer-bottom">
        <!-- Copyright Area -->
        <div class="copyright-area border-0">
            <p>Made with <i class="icofont-heart-alt"></i> By <a href="#">Theme Land</a></p>
        </div>
    </div>
</div>