<section id="benifits" class="section benifits-area ptb_100">
    <div class="container">
        <div class="row">
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-md-4">
                <div class="benifits-item text-center p-3">
                    <div class="feature-icon">
                        <img src="assets/img/features_icon_1.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Free Trail</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum impedit possimus eligendi, quam soluta qui.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-md-4">
                <div class="benifits-item text-center p-3">
                    <div class="feature-icon">
                        <img src="assets/img/features_icon_2.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Easy to use</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum impedit possimus eligendi, quam soluta qui.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-md-4">
                <div class="benifits-item text-center p-3">
                    <div class="feature-icon">
                        <img src="assets/img/features_icon_3.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">24/7 Support</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum impedit possimus eligendi, quam soluta qui.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-md-4">
                <div class="benifits-item text-center p-3">
                    <div class="feature-icon">
                        <img src="assets/img/features_icon_4.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Fast Loading</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum impedit possimus eligendi, quam soluta qui.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-md-4">
                <div class="benifits-item text-center p-3">
                    <div class="feature-icon">
                        <img src="assets/img/features_icon_5.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Responsive Design</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum impedit possimus eligendi, quam soluta qui.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-md-4">
                <div class="benifits-item text-center p-3">
                    <div class="feature-icon">
                        <img src="assets/img/features_icon_6.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Well Documented</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum impedit possimus eligendi, quam soluta qui.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>