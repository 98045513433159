<div class="blog">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-one></app-header-one>
        <app-breadcrumb-faq></app-breadcrumb-faq>
        <div class="bg-gray">
            <app-faq></app-faq>
        </div>
        <app-newsletter></app-newsletter>
        <app-footer></app-footer>
    </div>
</div>