import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FaqPageComponent } from './components/inner-pages/faq-page/faq-page.component';
import { LandingComponent } from './landing/landing.component';
import { PoliciesComponent } from './components/inner-pages/policies/policies.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConditionsComponent } from './components/inner-pages/conditions/conditions.component';
import { SocialDataDeletionComponent } from './components/social-data-deletion/social-data-deletion.component';


const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'faq-page', component: FaqPageComponent },
  { path: 'policies-page', component: PoliciesComponent },
  { path: 'terms-conditions', component: ConditionsComponent },
  { path: 'data-deletion', component: SocialDataDeletionComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes),
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [RouterModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class AppRoutingModule { }
