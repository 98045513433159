<section id="home" class="section welcome-area bg-inherit d-flex align-items-center h-100vh">
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-12 col-md-6 col-lg-6">
                <!-- Welcome Thumb -->
                <div class="welcome-thumb text-center">
                    <img src="assets/img/login_1.svg" alt="">
                </div>
            </div>
            <!-- Welcome Intro Start -->
            <div class="col-12 col-md-6 col-lg-5 pt-4 pt-sm-0">
                <div class="welcome-intro">
                    <h1>Sell your product more efficiently</h1>
                    <p class="my-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto beatae, asperiores tempore repudiandae saepe aspernatur unde voluptate sapiente quia ex.</p>
                    <div class="button-group">
                        <a href="#" class="btn btn-bordered d-none d-lg-inline-block"><span>Download</span></a>
                        <a class="video-btn" data-fancybox href="https://www.youtube.com/watch?v=hs1HoLs4SD0">
                            <span class="play-btn">
                                <i class="icofont-ui-play"></i>
                            </span>
                            <span class="play-text pl-2">
                                Play Video
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>