<section id="companies" class="branding-area ptb_50">
    <div class="container">
        <div class="row">
            <!-- Branding Slider -->
            <div class="branding-slider owl-carousel op-4 py-4 border-bottom">
                <!-- Single Brand -->
                <div class="single-brand p-3">
                    <img src="assets/img/revivalista.png" alt="">
                </div>
                <!-- Single Brand -->
                <div class="single-brand p-3">
                    <img src="https://firebasestorage.googleapis.com/v0/b/mercapp-a57d6.appspot.com/o/companies%2FMteQI7yEXMFRGoG0c07u%2FMteQI7yEXMFRGoG0c07u?alt=media&token=b508463d-4503-47b4-9b10-6c9ed8aa2cba" alt="">
                </div>
                <!-- Single Brand -->
                <div class="single-brand p-3">
                    <img src="https://firebasestorage.googleapis.com/v0/b/mercapp-a57d6.appspot.com/o/companies%2FM4mIRHeDSAwXVkQcSdoG%2FM4mIRHeDSAwXVkQcSdoG?alt=media&token=7cb7f2cd-e54f-42da-b93a-621eaf3fd1ce" alt="">
                </div>
                <div class="single-brand p-3">
                    <img src="https://firebasestorage.googleapis.com/v0/b/mercapp-a57d6.appspot.com/o/companies%2Foq0V0sWTcPgw8Ctto9Kx%2Flucuma2.jpg?alt=media&token=c6fbb8af-adb5-4ee1-a719-a3213b5863a1" alt="">
                </div>
                <div class="single-brand p-3">
                    
                </div>
               
            </div>
        </div>
    </div>
</section>