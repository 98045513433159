import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-data-deletion',
  templateUrl: './breadcrumb-data-deletion.component.html',
  styleUrls: ['./breadcrumb-data-deletion.component.css']
})
export class BreadcrumbDataDeletionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
