<footer class="section footer-area footer-bg">
    <!-- Footer Top -->
    <div class="footer-top ptb_100">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Logo -->
                        <a class="navbar-brand" href="#">
                            <img class="logo" src="assets/img/logo_mercapp.png" alt="">
                        </a>
                        <p class="text-white-50 mt-2 mb-3">Tu app para comprar y consumir productos de tus productores preferidos</p>
                        <!-- Social Icons -->
                        <div class="social-icons d-flex">
                            <a class="facebook" href="#">
                                <i class="fab fa-facebook-f"></i>
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3"></div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title text-white mb-2">Otros links</h3>
                        <ul>
                            <li class="py-2"><a class="text-white-50" href="/faq-page">FAQ</a></li>
                            <li class="py-2"><a class="text-white-50" href="/policies-page">Políticas</a></li>
                            <li class="py-2"><a class="text-white-50" href="/terms-conditions">Términos &amp; Condiciones</a></li>
                            <li class="py-2"><a class="text-white-50" href="/data-deletion">Borrar datos de acceso</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Footer Bottom -->
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Copyright Area -->
                    <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                        <!-- Copyright Left -->
                        <div class="copyright-left text-white-50">&copy; Copyrights 2020 Mercapp todos los derechos reservados</div>
                        <!-- Copyright Right -->
                        <div class="copyright-right text-white-50">Power By <a class="text-white-50" href="#">JIT innovations</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>