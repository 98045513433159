<header class="section header-area">
    <div id="appo-header" class="main-header-area nav-white">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <!-- Logo -->
                <a class="navbar-brand" href="#">
                    <img class="logo" src="assets/img/logo_mercapp.png" alt="">
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#appo-menu">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <!-- Appo Menu -->
                <div class="collapse navbar-collapse" id="appo-menu">
                    <!-- Header Items -->
                    <ul class="navbar-nav header-items ml-auto">
                        <li class="nav-item">
                            <a class="nav-link scroll" data-toggle="collapse" data-target="#appo-menu"
                                href="#companies">Empresas</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link scroll" data-toggle="collapse" data-target="#appo-menu"
                                href="#quick-start">Guía Rápida</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link scroll" data-toggle="collapse" data-target="#appo-menu"
                                href="#screenshots">Pantallas</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link scroll" data-toggle="collapse" data-target="#appo-menu"
                                href="#newsletter">Inicia</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link scroll" data-toggle="collapse" data-target="#appo-menu"
                                href="#download">Descarga</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link scroll" data-toggle="collapse" data-target="#appo-menu"
                                href="#contact">Contacto</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown-2" role="button"
                                data-toggle="dropdown">
                                Otros links
                            </a>
                            <!-- Blog Menu -->
                            <div class="dropdown-menu mega-menu blog-menu px-3 py-md-3">
                                <div class="row">
                                    <div class="col-12">
                                        <ul class="single-menu">
                                            <li><a class="dropdown-item" href="/policies-page">Políticas</a></li>
                                            <li><a class="dropdown-item" href="/terms-conditions">Términos y
                                                    condiciones</a></li>
                                            <li><a class="dropdown-item" href="/faq-page">FAQ</a></li>
                                            <li><a class="dropdown-item" href="/data-deletion">Borrar acceso</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item">
                            <a target="_blank" href="https://app.mimercapp.com">
                                <button type="button" class="btn btn-sm btn-primary">
                                    Inicia sessión
                                </button>
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</header>