import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeroOneComponent } from './components/hero/hero-one/hero-one.component';
import { HeroTwoComponent } from './components/hero/hero-two/hero-two.component';
import { HeroThreeComponent } from './components/hero/hero-three/hero-three.component';
import { HeroFourComponent } from './components/hero/hero-four/hero-four.component';
import { HeroFiveComponent } from './components/hero/hero-five/hero-five.component';
import { HeroSixComponent } from './components/hero/hero-six/hero-six.component';
import { HeroLandingComponent } from './components/hero/hero-landing/hero-landing.component';
import { HeroEightComponent } from './components/hero/hero-eight/hero-eight.component';
import { HeroNineComponent } from './components/hero/hero-nine/hero-nine.component';
import { PromoOneComponent } from './components/promo/promo-one/promo-one.component';
import { PromoTwoComponent } from './components/promo/promo-two/promo-two.component';
import { WorkComponent } from './components/work/work.component';
import { FeaturesComponent } from './components/features/features.component';
import { ScreenshotComponent } from './components/screenshot/screenshot.component';
import { ReviewsComponent } from './components/reviews/reviews.component';
import { TeamComponent } from './components/team/team.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { DownloadComponent } from './components/download/download.component';
import { ContactComponent } from './components/contact/contact.component';
import { ScrollupComponent } from './components/scrollup/scrollup.component';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { FooterComponent } from './components/footer/footer.component';
import { BrandingComponent } from './components/branding/branding.component';
import { HeaderOneComponent } from './components/header/header-one/header-one.component';
import { HeaderLandingComponent } from './components/header/header-landing/header-landing.component';
import { PromoThreeComponent } from './components/promo/promo-three/promo-three.component';
import { ReviewsPageComponent } from './components/inner-pages/reviews-page/reviews-page.component';
import { DownloadPageComponent } from './components/inner-pages/download-page/download-page.component';
import { NewsletterPageComponent } from './components/inner-pages/newsletter-page/newsletter-page.component';
import { ErrorOneComponent } from './components/inner-pages/error-page/error-one/error-one.component';
import { ErrorTwoComponent } from './components/inner-pages/error-page/error-two/error-two.component';
import { ContactPageComponent } from './components/inner-pages/contact-page/contact-page.component';
import { MaintenanceComponent } from './components/inner-pages/maintenance/maintenance.component';
import { ComingSoonComponent } from './components/inner-pages/coming-soon/coming-soon.component';
import { BreadcrumbAboutComponent } from './components/breadcrumb/breadcrumb-about/breadcrumb-about.component';
import { BreadcrumbPricingComponent } from './components/breadcrumb/breadcrumb-pricing/breadcrumb-pricing.component';
import { BreadcrumbReviewsComponent } from './components/breadcrumb/breadcrumb-reviews/breadcrumb-reviews.component';
import { BreadcrumbFaqComponent } from './components/breadcrumb/breadcrumb-faq/breadcrumb-faq.component';
import { BreadcrumbContactComponent } from './components/breadcrumb/breadcrumb-contact/breadcrumb-contact.component';
import { BreadcrumbBlogTwoColumnComponent } from './components/breadcrumb/breadcrumb-blog-two-column/breadcrumb-blog-two-column.component';
import { BreadcrumbBlogThreeColumnComponent } from './components/breadcrumb/breadcrumb-blog-three-column/breadcrumb-blog-three-column.component';
import { BreadcrumbBlogLeftSidebarComponent } from './components/breadcrumb/breadcrumb-blog-left-sidebar/breadcrumb-blog-left-sidebar.component';
import { BreadcrumbBlogRightSidebarComponent } from './components/breadcrumb/breadcrumb-blog-right-sidebar/breadcrumb-blog-right-sidebar.component';
import { BreadcrumbBlogDetailsLeftSidebarComponent } from './components/breadcrumb/breadcrumb-blog-details-left-sidebar/breadcrumb-blog-details-left-sidebar.component';
import { BreadcrumbBlogDetailsRightSidebarComponent } from './components/breadcrumb/breadcrumb-blog-details-right-sidebar/breadcrumb-blog-details-right-sidebar.component';
import { FaqComponent } from './components/faq/faq.component';
import { FaqPageComponent } from './components/inner-pages/faq-page/faq-page.component';
import { MapComponent } from './components/map/map.component';
import { LandingComponent } from './landing/landing.component';
import { PoliciesComponent } from './components/inner-pages/policies/policies.component';
import { BreadcrumbPoliciesComponent } from './components/breadcrumb/breadcrumb-policies/breadcrumb-policies.component';
import { HttpClientModule } from '@angular/common/http';
import { BreadcrumbConditionsComponent } from './components/breadcrumb/breadcrumb-conditions/breadcrumb-conditions.component';
import { ConditionsComponent } from './components/inner-pages/conditions/conditions.component';
import { SocialDataDeletionComponent } from './components/social-data-deletion/social-data-deletion.component';
import { DataDeletionComponent } from './components/inner-pages/data-deletion/data-deletion.component';
import { BreadcrumbDataDeletionComponent } from './components/breadcrumb/breadcrumb-data-deletion/breadcrumb-data-deletion.component';

@NgModule({
  declarations: [
    AppComponent,
    HeroOneComponent,
    HeroTwoComponent,
    HeroThreeComponent,
    HeroFourComponent,
    HeroFiveComponent,
    HeroSixComponent,
    HeroLandingComponent,
    HeroEightComponent,
    HeroNineComponent,
    PromoOneComponent,
    PromoTwoComponent,
    WorkComponent,
    FeaturesComponent,
    ScreenshotComponent,
    ReviewsComponent,
    TeamComponent,
    NewsletterComponent,
    DownloadComponent,
    ContactComponent,
    ScrollupComponent,
    PreloaderComponent,
    FooterComponent,
    LandingComponent,
    BrandingComponent,
    HeaderOneComponent,
    HeaderLandingComponent,
    PromoThreeComponent,
    ReviewsPageComponent,
    DownloadPageComponent,
    NewsletterPageComponent,
    ErrorOneComponent,
    ErrorTwoComponent,
    ContactPageComponent,
    MaintenanceComponent,
    ComingSoonComponent,
    BreadcrumbAboutComponent,
    BreadcrumbPricingComponent,
    BreadcrumbReviewsComponent,
    BreadcrumbFaqComponent,
    BreadcrumbContactComponent,
    BreadcrumbBlogTwoColumnComponent,
    BreadcrumbBlogThreeColumnComponent,
    BreadcrumbBlogLeftSidebarComponent,
    BreadcrumbBlogRightSidebarComponent,
    BreadcrumbBlogDetailsLeftSidebarComponent,
    BreadcrumbBlogDetailsRightSidebarComponent,
    FaqComponent,
    FaqPageComponent,
    MapComponent,
    LandingComponent,
    PoliciesComponent,
    BreadcrumbPoliciesComponent,
    BreadcrumbConditionsComponent,
    ConditionsComponent,
    SocialDataDeletionComponent,
    DataDeletionComponent,
    BreadcrumbDataDeletionComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
