<section id="blog" class="section blog-area ptb_100">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-3">
                <aside class="sidebar">
                    <!-- Single Widget -->
                    <div class="single-widget">
                        <!-- Category Widget -->
                        <div class="accordions widget catagory-widget" id="cat-accordion">
                            <div class="single-accordion blog-accordion">
                                <h5>
                                    <a role="button" class="collapse show text-uppercase d-block p-3"
                                        data-toggle="collapse" href="#accordion1">Red social
                                    </a>
                                </h5>
                                <!-- Category Widget Content -->
                                <div id="accordion1" class="accordion-content widget-content collapse show"
                                    data-parent="#cat-accordion">
                                    <!-- Category Widget Items -->
                                    <ul class="widget-items">
                                        <li>
                                            <a class="nav-link scroll d-flex p-3" href="#facebook">Facebook</a>
                                        </li>
                                        <li>
                                            <a class="nav-link scroll d-flex p-3" href="#gmail">Gmail</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </aside>
            </div>
            <div class="col-12 col-lg-9">
                <!-- Single Blog Details -->
                <article class="single-blog-details">
                    <!-- Blog Content -->
                    <div class="blog-content appo-blog ">
                        <!-- Blog Details -->
                        <div id="facebook" class="blog-details">
                            <h3 class="blog-title py-2 py-sm-3"><a href="#">Facebook</a></h3>
                            <p class="d-block d-sm-block">En caso de que hayas vinculado tu acceso a Mercapp con
                                facebook y quieres eliminar este acceso, aqui estan los pasos a seguir: </p>
                            <div class="footer-items">
                                <ul>
                                    <li class="py-2 ml-4">
                                        1. Ve a la <a href="https://www.facebook.com/settings?tab=applications"
                                            target="_blank"> configuración de Facebook</a>
                                        (https://www.facebook.com/settings?tab=applications)
                                        <br><br>
                                        <img src="../../../../assets/img/face-detele-1.png" alt="">

                                    </li>
                                    <li class="py-2 ml-4">
                                        2. Una vez en la pantalla de configuración, selecciona Mercapp y da click en el
                                        botón eliminar
                                        <br><br>
                                        <img src="../../../../assets/img/face-detele-2.png" alt="">
                                    </li>
                                    <li class="py-2 ml-4">
                                        3. En la ventana de confirmación; decide si quieres eliminar publicaciones y da
                                        click en Eliminar
                                        <br><br>
                                        <img src="../../../../assets/img/face-detele-3.png" alt="">
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <div id="gmail" class="blog-details">
                            <h3 class="blog-title py-2 py-sm-3"><a href="#">Gmail</a></h3>

                            <p class="d-block d-sm-block">En caso de que hayas vinculado tu acceso a Mercapp con
                                Gmail y quieres eliminar este acceso, aqui estan los pasos a seguir: </p>

                            <div class="footer-items">
                                <ul>
                                    <li class="py-2 ml-4">
                                        1. Ve a tu <a href="https://myaccount.google.com/"
                                            target="_blank"> cuenta</a> en el apartado de seguridad
                                        (https://myaccount.google.com/)
                                        <br><br>
                                        1.1 Localiza la sección "Third-party apps with account access" acceso con tu cuenta a applicaciones de terceros 
                                        <br><br>
                                        1.2 Da click en el vínculo azul de la sección "Manage third-party access" Administra tus accesos 
                                        <img src="../../../../assets/img/gmail-detele-1.png" alt="">

                                    </li>
                                    <li class="py-2 ml-4">
                                        2. Una vez en la pantalla de configuración, selecciona Mercapp y de click en el botón de "Remove Access"
                                        <br><br>
                                        <img src="../../../../assets/img/gmail-detele-2.png" alt="">
                                    </li>
                                    <li class="py-2 ml-4">
                                        3. En la ventana de confirmación; da click en ok
                                        <br><br>
                                        <img src="../../../../assets/img/gmail-detele-3.png" alt="">
                                    </li>
                                </ul>
                            </div>

                        </div>

                    </div>
                </article>
            </div>
        </div>
    </div>
</section>