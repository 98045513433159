<section id="features" class="section features-area ptb_100">
    <div class="shapes-container">
        <div class="shape bg-shape"></div>
    </div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-md-6 col-lg-6">
                <div class="features-text">
                    <!-- Headings -->
                    <div class="headings d-flex align-items-center mb-4">
                        <span class="text-uppercase d-none d-sm-block">Funciones</span>
                        <h2 class="text-capitalize">Nuestras carácteristicas</h2>
                    </div>
                    <p class="my-3">
                        Nuesto objetivo es proporcionar una plataforma para los productores locales y regionales, quienes quieran extender su forma de hacer negocio y estar más cerca con sus clientes.
                        <br><br>
                        A través de nuestra solución, buscamos conectar clientes y productores, proveer una alternativa para promocionar sus productos y generar más ventas en estos nuevos tiempos en donde el comercio eléctronico se esta convirtiendo en una herramienta fundamental para usuarios y comercios.
                    </p>
                    <!-- Counter List -->
                   
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-5 offset-lg-1">
                <!-- Featured Items -->
                <div class="featured-items">
                    <ul>
                        <li>
                            <!-- Single Features Item -->
                            <div class="single-features media p-3">
                                <!-- Features Title -->
                                <div class="features-title mr-3">
                                </div>
                                <!-- Features Text -->
                                <div class="features-text media-body">
                                    <h3>Servicio gratuito para tus usuarios</h3>
                                    <p>Sigue a tus proveedores o comercios favoritos, cominicate con ellos y mucho más</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <!-- Single Features Item -->
                            <div class="single-features media p-3">
                                <!-- Features Title -->
                                <div class="features-title mr-3">
                                </div>
                                <!-- Features Text -->
                                <div class="features-text media-body">
                                    <h3>Has tus pedidos</h3>
                                    <p>Chatea con tu proveedor, dale seguimiento a tus pedidos y coordina la entrega</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <!-- Single Features Item -->
                            <div class="single-features media p-3">
                                <!-- Features Title -->
                                <div class="features-title mr-3">
                                </div>
                                <!-- Features Text -->
                                <div class="features-text media-body">
                                    <h3>Administra tu negocio - membresía</h3>
                                    <p>Multi empresa, número ilimitado de productos y ofertas para tus clientes con una sola cuenta</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>