import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactInfo } from 'src/app/models/contact.info';
import { EmailService } from 'src/app/services/email-service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  form: FormGroup;

  constructor(private emailService: EmailService) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl('', {
        validators: [Validators.required],
      }),
      email: new FormControl('', {
        validators: [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
        ],
      }),
      subject: new FormControl('', {
        validators: [Validators.required],
      }),
      message: new FormControl('', {
        validators: [Validators.required],
      })
    });
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onSubmit() {
    if (!this.form.valid) {
      this.validateAllFormFields(this.form);
      return;
    }

    const contactInfo: ContactInfo = {
      name : this.form.value.name,
      email: this.form.value.email,
      subject: this.form.value.subject,
      message: this.form.value.message,
    };


    this.emailService.sendContactInfo(contactInfo).subscribe(
      (res) => {
        console.log('HTTP response', res);
        this.form.reset();
      },
      (err) => {
        console.log('Error in UI: ', err);
      }
    );
  }


}
