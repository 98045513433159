<div class="blog">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-one></app-header-one>
        <app-breadcrumb-conditions></app-breadcrumb-conditions>
        <section id="blog" class="section blog-area ptb_100">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-3">
                        <aside class="sidebar">
                            <!-- Single Widget -->
                            <div class="single-widget">
                                <!-- Category Widget -->
                                <div class="accordions widget catagory-widget" id="cat-accordion">
                                    <div class="single-accordion blog-accordion">
                                        <h5>
                                            <a role="button" class="collapse show text-uppercase d-block p-3"
                                                data-toggle="collapse" href="#accordion1">Secciones
                                            </a>
                                        </h5>
                                        <!-- Category Widget Content -->
                                        <div id="accordion1" class="accordion-content widget-content collapse show"
                                            data-parent="#cat-accordion">
                                            <!-- Category Widget Items -->
                                            <ul class="widget-items">
                                                <li>
                                                    <a class="nav-link scroll d-flex p-3"
                                                        href="#contract-relationship">Relación contractual</a>
                                                </li>
                                                <li>
                                                    <a class="nav-link scroll d-flex p-3"
                                                        href="#services">Los Servicios</a>
                                                </li>
                                                <li>
                                                    <a class="nav-link scroll d-flex p-3"
                                                        href="#gathering-no-personal-data">Uso de Servicios y conducta</a>
                                                </li>
                                                <li>
                                                    <a class="nav-link scroll d-flex p-3"
                                                        href="#payment">Pago</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                    <div class="col-12 col-lg-9">
                        <!-- Single Blog Details -->
                        <article class="single-blog-details">
                            <!-- Blog Content -->
                            <div class="blog-content appo-blog">
                                <!-- Blog Details -->
                                <div id="contract-relationship" class="blog-details">
                                    <h3 class="blog-title py-2 py-sm-3"><a href="#">Relación contractual</a></h3>
                                    <p class="d-block d-sm-block">
                                        Las condiciones de uso que se describen en este documento "Condiciones" regulan el acceso o uso que usted haga, como persona, de páginas web, contenido, aplicaciones, productos o servicios "Servicios" que ofrece <strong> Mercapp </strong>
                                    </p>
                                    <p class="d-block d-sm-block">
                                        Mediante el uso y acceso de los Servicios usted esta de acuerdo vincularse jurídicamente por estas Condiciones, que establecen una relación contractual entre usted y Mercapp. Si usted no acepta estas condiciones, no podrá acceder o hacer uso de ninguno de los Servicios. Estas condiciones sustituyen expresamente los acuerdos o compromisos previos con usted. En caso de un incumplimiento a los presente Términos y Condiciones y/o a las Guías y buenas practicas de use por su parte. Mercapp podrá negarle o revocarle el acceso a su cuenta y/o a los Servicios sí usted no cumple con lo descritó en este contrato.
                                    </p>
                                    <p class="d-block d-sm-block">
                                        Mercapp podrá modificar o poner fin a estos Términos y Condiciones o a cualquier de los Servicios ofrecidos en las aplicaciones de manera total o parcial.
                                        Se podrán aplicar condiciones adicionales a determinados Servicios, pudiendo consistir en políticas para un evento específico, promociones diversas, nuevas funcionalidades o actualización de servicios o Términos y Condiciones. Las modificaciones o agregados serán efectivas despúes de la publicación por parte de Mercapp en esta ubicación. 
                                    </p>

                                </div>
                                <div id="services" class="blog-details">
                                    <h3 class="blog-title py-2 py-sm-3"><a href="#">Los Servicios</a></h3>
                                    <p class="d-block d-sm-block">
                                        Los Servicios constituyen en una plataforma de technología que permite a los usuarios de aplicaciones móviles y páginas web de Mercapp administrar productos, empresas o ofertas, así como poder conectarse con proveedores para poder realizar pedidos en línea.
                                    </p>
                                    <p class="d-block d-sm-block">
                                        Mercapp, por el momento, NO es una aplicación de Delivery, por lo que ni el cliente, ni empresa podrán hacer resposable a Mercapp, ni a sus servicios en caso de que decidan utilizar este método. 
                                        Sí una empresa o proveedor, puede enviar a domicilio, la lógistica, seguro, actualización y compañia de delivery queda fuera de la resposabilidad de Mercapp, así como el cliente que acepte estas condiciones queda sujeto a dar seguimiento con la empresa.
                                    </p>
                                    <h4 class="blog-title py-2 py-sm-3">Licencia</h4>
                                    <p class="d-block d-sm-block">
                                        Sujeto a seguir y cumplir estas Condiciones, Mercapp le otorga una licencia límitada, no exclusiva, revocable, no transferiable para:
                                        <br>
                                        1. El acceso y uso de la página web y aplicaciones en su dispositivo(s) personal(es) solo en relación con su uso de los Servicios;
                                        <br>
                                        2. El acceso y uso de cualquier contenido, información y material relacionado que puede ponerse a disposición a través de los Servicios, en cada caso solo para su uso personal, no comercial. Mercapp y sus licencias se reservan cualquier derecho que no haya sido expresamente otorgado por el presente.a
                                    </p>

                                    <h4 class="blog-title py-2 py-sm-3">Restricciones</h4>
                                    <p class="d-block d-sm-block">
                                        Usted no podrá:
                                        <br>
                                        1. Remover derechos de autor, marca registrada u otra nota de propiedad de cualquier parte de los Servicios
                                        <br>
                                        2. Reproducir, modificar, preparar negocios o obras derivadas sobre los Servicios, distribuir, licenciar, arrendar, revender, transferir, exhibir públicamente, transmitir, retrasmitir o explorar de otra forma los Servicios, con excepción de que se cuente con un permiso expreso para el este propósito.
                                        <br>
                                        3. Descompilar, realizar ingeniería inversa o descargar los Servicios, todo código fuente de los Servicios son propiedad de Mercapp
                                        <br>
                                        4. Ejecutar o generar cualquier programa o script con el objeto de extraer, analizar o hacer alguna minería de datos, prospección de datos de cualquier parte de los Servicios. Así como sobrecargar o bloquear indebidamente la operación o cualquiere funcionalidad.
                                        <br>
                                        5. Intentar obtener un acceso no autoriaza, o perjudicar cualquier aspecto de los Servicios o redes delacionadas a estos Servicios.
                                    </p>

                                    <h5 class="blog-title py-2 py-sm-3">
                                        Los Serviciocs y todos los derechos relativos a estos son y permanecerán a la propiedad Mercapp. 
                                    </h5>
                                    
                                </div>

                                <div id="gathering-no-personal-data" class="blog-details">
                                    <h3 class="blog-title py-2 py-sm-3"><a href="#">Uso de los Servicios</a></h3>
                                    <p class="d-block d-sm-block">
                                        Con el propósito de usar los Servicios, usted debe registrarse y mantener activa una cuenta personal de usuario de los Servicios ("Cuenta de usuario").
                                        Para obtener dicha cuenta, debe tener como mínimo 18 años cumplidos, o tener la moyoría de edada legal en caso de que sea diferente a los 18 años. 
                                        El registro de la cuenta require que ingrese información personal, como nombre, correo electrónico, teléfono celular.a
                                        Usted se compromete a proporcionar información veraz y mantenerla actualizada, si no mantiene la informacion podrá resultar en su imposibilidad para acceder y utilizar los Serviios.
                                        <br>
                                        Usted es resposable de toda la actividad que ocurre en su Cuenta y se compromete a mantener en todo momento de forma segura y secreta el nombre de usuario y contraseña. Tambíen se compromete a cumplir sus compromisos monetarios adquiridos en los pedidos y transacciones que los Servicios le permitan con empresas y proveedores.
                                    </p>
                                </div>

                                <div id="payment" class="blog-details">
                                    <h3 class="blog-title py-2 py-sm-3"><a href="#"> Pago</a></h3>
                                    <p class="d-block d-sm-block">
                                       Usted entiende que el uso de los Servicios, puede derivar en cargos por bienes o productos que reciba de un Tercer proveedor. Usted se compromete a efectuar el pago pactado puntualmente cuando el producto sea recogido o entregado en su domicilio.
                                       <br>
                                       Mercapp es una aplicación de uso gratuito para los usuarios, sin embargo, los pagos y compromisos adquiridos por hacer pedidos, deben de ser cumplidos en todo momento, de lo contrario la información de su cuenta puede ser facilitada para iniciar procesos legales en su contra.a
                                       <br>
                                       Para proveedores y/o empresas, Mercapp cobra una mensualidad para tener derechos a publicar, administrar productos y ofertas, así como manejar los pedidos de los clientes.
                                    </p>
                                </div>

                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </section>
        <app-footer></app-footer>
    </div>
</div>