<section id="quick-start" class="section work-area ptb_100">
    <!-- Work Slider Wrapper -->
    <div class="work-wrapper d-none d-md-block">
        <div class="work-slider-wrapper" data-aos="zoom-in">
            <!-- Work Slider -->
            <ul class="work-slider owl-carousel">
                <li class="slide-item">
                    <img src="assets/img/perfil.png" alt="">
                </li>
                <li class="slide-item">
                    <img src="assets/img/select_product.png" alt="">
                </li>
                <li class="slide-item">
                    <img src="assets/img/cart.png" alt="">
                </li>
                <li class="slide-item">
                    <img src="assets/img/monitor_order.png" alt="">
                </li>
            </ul>
        </div>
    </div>
    <div class="container">
        <div class="row justify-content-center d-md-none">
            <!-- Section Heading -->
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Algunos pasos para empezar</h2>
                </div>
            </div>
        </div>
        <!-- Work Content -->
        <div class="row justify-content-end justify-content-lg-between work-content" id="work-slider-pager">
            <div class="col-12 col-sm-6">
                <a href="#" class="pager-item active">
                    <!-- Single Work -->
                    <div class="single-work d-inline-block text-center p-4">
                        <h3 class="mb-2">Registrate</h3>
                        <p>Descarga y registrate en la app, puedes usar datos de Gmail, Facebook o crear tu cuenta manualmente</p>
                    </div>
                </a>
            </div>
            <div class="col-12 col-sm-6">
                <a href="#" class="pager-item">
                    <!-- Single Work -->
                    <div class="single-work d-inline-block text-center p-4">
                        <h3 class="mb-2">Busca tu producto</h3>
                        <p>Navega y encuentra los productos de tus productores favoritos, hay muchas opciones a elegir</p>
                    </div>
                </a>
            </div>
            <div class="col-12 col-sm-6">
                <a href="#" class="pager-item">
                    <!-- Single Work -->
                    <div class="single-work d-inline-block text-center p-4">
                        <h3 class="mb-2">Agrega a tu carrito</h3>
                        <p>Agrega todos los productos que deseas comprar a tu carrito, el carrito te organiza tus pedidos por productor</p>
                    </div>
                </a>
            </div>
            <div class="col-12 col-sm-6">
                <a href="#" class="pager-item">
                    <!-- Single Work -->
                    <div class="single-work d-inline-block text-center p-4">
                        <h3 class="mb-2">Monitorea tu pedido</h3>
                        <p>Una vez realizado tu pago, monitorea tu pedido y chatea con tu productor </p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>