<section id="screenshots" class="section screenshots-area ptb_100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <!-- App Screenshot Slider Area -->
                <div class="app-screenshots">
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/login.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/login.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/landing.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/landing.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/profile.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/profile.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/catalogo_prods.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/catalogo_prods.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/detalles_prod.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/detalles_prod.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                      <!-- Single Screenshot Item -->
                      <div class="single-screenshot">
                        <img src="assets/img/myorders.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/myorders.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>