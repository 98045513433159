<div class="inner-wrapper maintenance-wrapper d-flex flex-column align-items-center justify-content-between p-4">
    <a href="index.html">
        <img src="assets/img/inner_logo.png" alt="">
    </a>
    <!-- ***** Maintenance Area Start ***** -->
    <div class="maintenance-area">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-md-6 order-2 order-md-1">
                    <!-- Maintenance Content -->
                    <div class="maintenance-content my-5 my-md-0">
                        <div class="alert alert-warning d-inline-flex">
                            Sorry for the innovance!
                        </div>
                        <h1>Server is under maintenance</h1>
                        <p class="my-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto beatae, asperiores tempore repudiandae saepe aspernatur unde voluptate sapiente quia ex.</p>
                        <a href="#" class="btn btn-primary">Learn More</a>
                    </div>
                </div>
                <div class="col-12 col-sm-10 col-md-6 order-1 order-md-2 mx-auto pt-4 pt-md-0">
                    <img src="assets/img/maintenance.png" alt="">
                </div>
            </div>
        </div>
    </div>
    <!-- ***** Maintenance Area End ***** -->
    <div class="footer-bottom">
        <!-- Copyright Area -->
        <div class="copyright-area pt-4 pt-md-0">
            <p>Made with <i class="icofont-heart-alt"></i> By <a href="#">Theme Land</a></p>
        </div>
    </div>
</div>