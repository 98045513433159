<div class="blog">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-one></app-header-one>
        <app-breadcrumb-policies></app-breadcrumb-policies>
        <section id="blog" class="section blog-area ptb_100">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-3">
                        <aside class="sidebar">
                            <!-- Single Widget -->
                            <div class="single-widget">
                                <!-- Category Widget -->
                                <div class="accordions widget catagory-widget" id="cat-accordion">
                                    <div class="single-accordion blog-accordion">
                                        <h5>
                                            <a role="button" class="collapse show text-uppercase d-block p-3"
                                                data-toggle="collapse" href="#accordion1">Secciones
                                            </a>
                                        </h5>
                                        <!-- Category Widget Content -->
                                        <div id="accordion1" class="accordion-content widget-content collapse show"
                                            data-parent="#cat-accordion">
                                            <!-- Category Widget Items -->
                                            <ul class="widget-items">
                                                <li>
                                                    <a class="nav-link scroll d-flex p-3"
                                                        href="#collect-data-treatment">Obtención y tratamiento de
                                                        datos</a>
                                                </li>
                                                <li>
                                                    <a class="nav-link scroll d-flex p-3"
                                                        href="#purpose-data-treatment">Propósito del tratamiento de
                                                        datos</a>
                                                </li>
                                                <li>
                                                    <a class="nav-link scroll d-flex p-3"
                                                        href="#gathering-no-personal-data">Recopilación y tratamiento
                                                        datos no personales</a>
                                                </li>
                                                <li>
                                                    <a class="nav-link scroll d-flex p-3"
                                                        href="#spread-data">Divulgación a terceros</a>
                                                </li>
                                                <li>
                                                    <a class="nav-link scroll d-flex p-3"
                                                        href="#personal-data-protection">Protección de datos
                                                        personales</a>
                                                </li>
                                                <li>
                                                    <a class="nav-link scroll d-flex p-3"
                                                        href="#localization-service">Servicios de localización</a>
                                                </li>
                                                <li>
                                                    <a class="nav-link scroll d-flex p-3"
                                                        href="#webpage-other-services">Páginas web y servicios de
                                                        terceros</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                    <div class="col-12 col-lg-9">
                        <!-- Single Blog Details -->
                        <article class="single-blog-details">
                            <!-- Blog Content -->
                            <div class="blog-content appo-blog">
                                <!-- Blog Details -->
                                <div id="collect-data-treatment" class="blog-details">
                                    <h3 class="blog-title py-2 py-sm-3"><a href="#">Obtención y tratamiento de datos de
                                            carácter personal</a></h3>
                                    <p class="d-block d-sm-block">Los datos de carácter personal son los que pueden ser
                                        utilizados para
                                        identificar a una persona o ponerse en contacto con ella.</p>
                                    <p class="d-block d-sm-block">
                                        Mercapp puede solicitar datos personales de usuarios al acceder a
                                        aplicaciones de la empresa o de otras empresas afiliadas así como la
                                        posibilidad de que entre estas empresas puedan compartir esos datos
                                        para mejorar los productos y servicios ofrecidos. Si no se facilitan esos
                                        datos personales, en muchos casos no podremos ofrecer los productos o
                                        servicios solicitados.

                                    </p>
                                    <p class="d-block d-sm-block">
                                        Estos son algunos ejemplos de las categorías de datos de carácter
                                        personal que Mercapp puede recoger y la finalidad para los que
                                        puede llevar a cabo el tratamiento de estos datos.
                                    </p>
                                    <p class="d-block d-sm-block">
                                        ¿Qué datos de carácter personal se pueden recopilar?
                                    </p>

                                    <div class="footer-items">
                                        <ul>
                                            <li class="py-2 ml-4">Al crear un ID, solicitar un crédito comercial,
                                                comprar un producto,
                                                descargar una actualización de software, se recopilan diferentes
                                                datos, como nombre, dirección postal, número de teléfono,
                                                dirección de correo electrónico o los datos de la tarjeta de crédito.
                                            </li>
                                            <li class="py-2 ml-4">
                                                Cuando se comparten contenidos con familiares y amigos o se
                                                invita a otras personas a participar en los servicios o foros,
                                                pueden recogerse los datos que facilitamos sobre esas personas,
                                                como su nombre, domicilio, correo electrónico y número de
                                                teléfono. Se utilizarán dichos datos para completar sus pedidos,
                                                mostrarle el producto o servicio correspondiente o para combatir
                                                el fraude.
                                            </li>

                                        </ul>
                                    </div>

                                </div>
                                <div id="purpose-data-treatment" class="blog-details">
                                    <h3 class="blog-title py-2 py-sm-3"><a href="#">Propósito del tratamiento de datos
                                            de carácter personal</a></h3>
                                    <p class="d-block d-sm-block">Mercapp podrá utilizar los datos personales
                                        recabados para:</p>
                                    <div class="footer-items">
                                        <ul>
                                            <li class="py-2 ml-4">Los datos de carácter personal recopilados permiten
                                                mantenerle
                                                informado acerca de los últimos productos, las actualizaciones
                                                de software disponibles y los próximos eventos.
                                            </li>
                                            <li class="py-2 ml-4">También se utilizan los datos de carácter personal
                                                como
                                                ayuda
                                                para elaborar, perfeccionar, gestionar,
                                                proporcionar y mejorar los productos, servicios, contenidos y
                                                publicidad, y con el propósito de evitar pérdidas y fraudes.

                                            </li>

                                            <li class="py-2 ml-4">Pueden utilizarse los datos de carácter personal para
                                                comprobar la identidad, colaborar en la identificación de
                                                usuarios y decidir los servicios apropiados.
                                            </li>

                                            <li class="py-2 ml-4">También se utilizan esos datos de carácter personal
                                                con propósitos internos, incluyendo auditorías, análisis de datos
                                                y sondeos, para mejorar los productos, servicios y
                                                comunicaciones a clientes.
                                            </li>

                                            <li class="py-2 ml-4">Si participa en un sorteo, un concurso o una
                                                promoción, pueden
                                                usarse los datos proporcionados para administrar estos
                                                programas.
                                            </li>

                                        </ul>
                                    </div>
                                </div>

                                <div id="gathering-no-personal-data" class="blog-details">
                                    <h3 class="blog-title py-2 py-sm-3"><a href="#">Recopilación y tratamiento de datos
                                            de carácter nopersonal</a></h3>
                                    <p class="d-block d-sm-block">
                                        Mercapp también recopilará datos de un modo que, por sí
                                        mismos, no pueden ser asociados directamente a una persona
                                        determinada. Estos datos de carácter no personal se pueden recopilar,
                                        tratar, transferir y publicar con cualquier intención. Estos son algunos
                                        ejemplos de las clases de datos de carácter no personal que
                                        Mercapp puede recopilar y los fines para los que se realiza su
                                        tratamiento:
                                    </p>
                                    <div class="footer-items">
                                        <ul>
                                            <li class="py-2 ml-4">Datos tales como profesión, idioma, código postal,
                                                identificador
                                                único de dispositivo, etc. para comprender mejor la conducta de
                                                nuestros clientes y mejorar nuestros productos, servicios y
                                                anuncios publicitarios.
                                            </li>
                                            <li class="py-2 ml-4">Datos sobre cómo se usan determinados servicios,
                                                incluidas las
                                                consultas de búsqueda. Esta información se puede utilizar para
                                                incrementar la importancia de los resultados que aportan los
                                                servicios ofrecidos.

                                            </li>

                                            <li class="py-2 ml-4">Datos sobre cómo usa su dispositivo y las aplicaciones
                                                para
                                                facilitar a los desarrolladores la mejora de esas aplicaciones.
                                            </li>

                                        </ul>
                                    </div>
                                    <p class="d-block d-sm-block">
                                        Si juntamos datos de carácter no personal con datos personales, los
                                        datos mezclados serán tratados como datos personales mientras sigan
                                        estando combinados.
                                    </p>
                                </div>

                                <div id="spread-data" class="blog-details">
                                    <h3 class="blog-title py-2 py-sm-3"><a href="#"> Divulgación a terceros</a></h3>
                                    <p class="d-block d-sm-block">
                                        Ocasionalmente Mercapp puede facilitar determinados datos de
                                        carácter personal a socios estratégicos que trabajen con nosotros para
                                        proveer productos y servicios o nos ayudan en nuestras actividades de
                                        marketing. No se compartirán los datos con ningún tercero para sus
                                        propios fines de marketing.
                                    </p>
                                    <h4 class="blog-title py-2 py-sm-3">Otros terceros</h4>
                                    <p class="d-block d-sm-block">
                                        Es posible que Mercapp divulgue datos de carácter personal
                                        por mandato legal, en el marco de un proceso judicial o por petición de
                                        una autoridad pública, tanto dentro como fuera de su país de residencia.
                                        Igualmente se puede publicar información personal si es necesaria o
                                        conveniente por motivos de seguridad nacional, para acatar la legislación
                                        vigente o por otras razones relevantes de orden público.

                                    </p>
                                </div>

                                <div id="personal-data-protection" class="blog-details">
                                    <h3 class="blog-title py-2 py-sm-3"><a href="#"> Protección de datos de carácter
                                            personal</a></h3>
                                    <p class="d-block d-sm-block">
                                        Mercapp garantizará la protección de los datos personales
                                        mediante cifrado durante el tránsito y, los alojados en instalaciones,
                                        con medidas de seguridad físicas.

                                    </p>
                                    <p class="d-block d-sm-block">
                                        Al usar ciertos productos, servicios o aplicaciones o al publicar
                                        opiniones en foros, salas de chat o redes sociales, el contenido y los
                                        datos de carácter personal que se comparta serán visible para otros
                                        usuarios, que tendrán la posibilidad de leerlos, compilarlos o usarlos.
                                        Usted será responsable de los datos de carácter personal que
                                        distribuya o proporcione en estos casos.

                                    </p>

                                    <h4 class="blog-title py-2 py-sm-3">Integridad y conservación de datos de carácter
                                        personal
                                    </h4>
                                    <p class="d-block d-sm-block">
                                        Mercapp garantizará la exactitud y la calidad de los datos
                                        personales, se conservarán durante el tiempo necesario para cumplir los
                                        fines para los que fueron recabados, salvo que la ley exija conservarlos
                                        durante más tiempo.

                                    </p>

                                    <h4 class="blog-title py-2 py-sm-3">Acceso a los datos de carácter personal
                                    </h4>
                                    <p class="d-block d-sm-block">
                                        Respecto a los datos de carácter personal que conservamos,
                                        Mercapp le ofrece acceso a ellos para cualquier fin, incluyendo las
                                        solicitudes de rectificación en caso de que sean incorrectos o de
                                        eliminación en caso de no estar obligados a conservarlos por imperativo
                                        legal o por razones legítimas de negocio. Nos reservamos el derecho a
                                        no tramitar aquellas solicitudes que sean improcedentes o vejatorias,
                                        que pongan en riesgo la privacidad de terceros, que resulten inviables o
                                        para las que la legislación local no exija derecho de acceso. Las
                                        solicitudes de acceso, rectificación o eliminación podrán enviarse a
                                        la cuenta de correo electrónico mercapp.solution@gmail.com
                                        
                                    </p>

                                </div>

                                <div id="localization-service" class="blog-details">
                                    <h3 class="blog-title py-2 py-sm-3"><a href="#">Servicios de localización</a>
                                    </h3>
                                    <p class="d-block d-sm-block">
                                        Para prestar servicios de localización Mercapp podrá reunir,
                                        utilizar y compartir datos exactos sobre ubicaciones, incluyendo la
                                        situación geográfica en tiempo real de su ordenador o de su dispositivo.
                                        Estos datos de localización se recogen de manera anónima de forma que no pueden utilizarse para
                                        identificarlo con otros datos personales diferentes a los asociados con su cuenta, y son usados para suministrar y mejorar sus
                                        productos y servicios de localización.
                                    </p>
                                </div>

                                <div id="webpage-other-services" class="blog-details">
                                    <h3 class="blog-title py-2 py-sm-3"><a href="#">Páginas web y servicios de
                                            terceros</a></h3>
                                    <p class="d-block d-sm-block">
                                        Las aplicaciones pueden contener enlaces a páginas web, productos y
                                        servicios de terceros. También pueden utilizar u ofrecer productos o
                                        servicios de terceros. La recogida de datos por parte de terceros,
                                        introduciendo de datos sobre ubicaciones geográficas o datos de
                                        contacto, se guiará por sus respectivas políticas de privacidad. Le
                                        recomendamos consultar las políticas de privacidad de esos terceros.

                                    </p>
                                </div>




                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </section>
        <app-footer></app-footer>
    </div>
</div>