<section id="download" class="section download-area ptb_100">
    <!-- Shapes Container -->
    <div class="shapes-container d-none d-sm-block">
        <div class="shape-2"></div>
        <div class="shape-3"></div>
    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
                <!-- Download Text -->
                <div class="download-text text-center">
                    <h2>Descarga la app</h2>
                    <h4>Muy pronto</h4>
                    <!-- Store Buttons -->
                    <div class="button-group store-buttons">
                        <a href="#" class="btn btn-bordered">
                            <i class="icofont icofont-brand-android-robot dsp-tc"></i>
                            <p class="dsp-tc">Obtenla en
                                <br> <span>Google Play</span></p>
                        </a>
                        <!--<a href="#" class="btn btn-bordered">
                            <i class="icofont icofont-brand-apple dsp-tc"></i>
                            <p class="dsp-tc">Disponible en
                                <br> <span>Apple Store</span></p>
                        </a>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>