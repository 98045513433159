<section id="contact" class="contact-area bg-gray ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Contacto</h2>
                    <p class="d-none d-sm-block mt-4">Sí quieres recibir más información o te interesa ponerte en
                        contacto con nosotros, ayudanos a llenar los siguientes datos</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- Contact Box -->
                <div class="contact-box text-center">
                    <!-- Contact Form -->
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" formControlName="name" placeholder="Nombre">
                                    <ng-container *ngIf="!form.get('name').valid && form.get('name').touched">
                                        <span class="text-danger">Por favor agrega un nombre</span>
                                    </ng-container>
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" formControlName="email"
                                        placeholder="Email">
                                    <ng-container *ngIf="!form.get('email').valid && form.get('email').touched">
                                        <span class="text-danger">El email es requerido</span>
                                    </ng-container>
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" formControlName="subject"
                                        placeholder="Asunto">
                                    <ng-container *ngIf="!form.get('subject').valid && form.get('subject').touched">
                                        <span class="text-danger">Agrega un asunto</span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <textarea class="form-control" formControlName="message"
                                        placeholder="Mensaje"></textarea>
                                    <ng-container *ngIf="!form.controls.message.valid && form.get('message').touched">
                                        <span class="text-danger text-">Escribe un mensaje</span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-12">
                                <button class="btn btn-bordered mt-3 mt-sm-4" type="submit">Enviar Mensaje</button>
                            </div>
                        </div>
                    </form>
                    <p class="form-message"></p>
                </div>
            </div>
        </div>
    </div>
</section>